@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:400,700');

$primary: #555; /* MAIN COLOR */
$secondary: #02c0f3; /* SECONDARY COLOR */
$gray: #666; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #d5d5d5;

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


body {
	font-size: 1.8em;
  line-height: 1.9em;
  font-family: 'Josefin Sans', sans-serif;


	h1,h2,h3,h4,h5,h6 {
		font-size:1.5em;
	}
}

nav {
	z-index: 10;
}
.navbar-brand {
    height: 100px; // this is what determine the height of the nav
    padding: 5px;
		@media(max-width: 767px){
			height: 70px; // nav height mobile,(feel free to change this values as you please)
			.logo {
				max-height: 60px; // logo height changed on mobile
			}
		}
}
.navbar .navbar-nav {
> li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: black;
			transition: 1s all ease;


			@media (max-width: 767px) {
				margin-top: 0;
			    display: inline-block;
			    height: 25px;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
}
.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn{
	text-align: center;
	border: 1px solid $secondary;
	background: transparent;
	color: $secondary;
	padding: 0.7em 1em;
	font-size: 1.2em;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 2em 0em;
  @media(max-width: 767px){
    font-size: .8em;
  }

	&:hover {
    color: $primary;
    border: 1px solid $primary;
    background: transparent;
		text-decoration: none;
    @media(max-width: 1024px){
      border: 1px solid $secondary;
    	background: transparent;
    	color: $secondary;
    }

	}
}

.btn-default {
	@include btn;
}
// mixins end here

// sections start
section {
  padding: 120px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media(max-width: 991px){
    padding: 90px 0;
  }
  @media(max-width: 767px){
    padding: 60px 0;
  }
  h1 {
    border-radius: 5px;
    border-bottom: 1px solid $secondary;
    padding: 25px 30px;
    color: $primary;
    margin-bottom: 35px;
    font-size: 2em;
    text-transform: uppercase;
    @media(max-width:767px){
      font-size: 1.5em;

    }
  }
  p{
    margin-bottom: 35px;
  }
}
.section-a {
  background-image: url('../img/bg1.jpg');
  padding: 200px 0;
  color: $blk;
  @media(max-width: 1024px){
    padding: 150px 0;
  }
  @media(max-width: 767px){
    padding:100px 0;
  }
  @media(max-width: 600px){
    padding:80px 0;
  }
  @media(max-width: 500px){
    padding:20px 0;
  }
  h1{
    font-size: 2em;
    @media(max-width: 1024px){
      font-size: 1.5em;
    }
    @media(max-width: 767px){
      font-size: 1.2em;
    }
  }
}
.section-b {
  background-color: lighten($primary,65% );
    padding: 150px 0;
  @media(max-width: 767px){
    padding:100px 0;
  }
  @media(max-width: 600px){
    padding:80px 0;
  }

  h3 {
    text-transform: uppercase;
    font-size: 1.5em;
    border-bottom: 1px solid $secondary;
    padding-bottom: 20px;
    @media(max-width: 1024px){
      font-size: 1.4em;
    }
    @media(max-width: 767px){
      font-size: 1.2em;
    }
  }
  h1 {
    font-size: 2em;
    @media(max-width: 1024px){
      font-size: 1.5em;
    }
    @media(max-width: 767px){
      font-size: 1.2em;
    }
  }
}
.section-c{
  background-color: lighten($wht,55% );
  background-image: url('../img/bg1.jpg');
  padding: 150px 0;
@media(max-width: 767px){
  padding:100px 0;
}
@media(max-width: 600px){
  padding:80px 0;
}

}
.section-e{
  background-color: lighten($wht,55% );
  background-image: url('../img/img3.jpg');
  padding: 350px 0;
@media(max-width: 767px){
  padding:250px 0;
}
@media(max-width: 600px){
  padding:200px 0;
}
@media(max-width: 500px){
  padding:150px 0;
}
@media(max-width: 400px){
  padding:100px 0;
}
}
.section-d{
  background-color: lighten($primary,65% );
  padding: 80px 0;
  color: $wht;

  @media(max-width: 1024px){
    padding: 60px 0;
  }
  i {
    margin-right: 10px;
    color: $secondary;
  }
  a {
    color: $blk;
    text-decoration: none;

  }
  p {
    color: $blk;
    font-size: 1.2em;
    margin: 20px 0px;
    @media(max-width: 767px){
      font-size: 1em;
    }
  }
  h3 {
    color: $blk;
  }
  h2 {
    font-size: 2em;
    @media(max-width: 1024px){
      font-size: 1.5em;
    }
    @media(max-width: 767px){
      font-size: 1em;
    }
  }
  h1 {
    font-size: 2em;
    @media(max-width: 1024px){
      font-size: 1.5em;
    }
    @media(max-width: 767px){
      font-size: 1.2em;
    }
  }
}
// sections end
// sections end


.modal-dialog {
	max-width: 500px;
	width: 100%;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	a.btn, a.btn-default, button {
		@include btn
		margin: 0;
		display: block;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
	padding: 50px 0px 20px;
	background: $secondary;
	color: $wht;
	font-size: 15px;

	a {
		color: $wht;
		white-space: nowrap;
		font-size: 15px;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}



.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}
